import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import * as reqTypes from '../../redux/actionType'
import config from './config'
import storage from '../../storage';

const fapp=firebase.initializeApp(config);
export const auth = firebase.auth();
export const firestore = firebase.firestore();


function useFirebase() { 
    const dispatch=useDispatch();
 const [firebaseStore,setFirebaseStore]=useState(null);
 const [firebaseApp,setFirebaseApp]=useState(null);
 const [firebaseObj,setFirebaseObj]=useState(null);
 const [lastInsertedItem,setLastInsertedItem]=useState(null);
   useEffect(async()=>{
       if(!firebaseStore){
           let fa = fapp;
           const db = firestore;
          // debugger;
        //    firebase.auth().createUserWithEmailAndPassword("rajesh_ng@hotmail.com", "r@jeshng1").catch(function(error) {
        //    debugger;
        //     var errorCode = error.code;
        //     var errorMessage = error.message;
           
        //   });
        // firebase.auth().signInWithEmailAndPassword("rajesh_ng@hotmail.com", "werwerr@jeshng1").catch(function(error) {
        //   debugger;
        //     var errorCode = error.code;
        //     var errorMessage = error.message;
         
        //   });
           setFirebaseStore(db);
           setFirebaseApp(fa);
           setFirebaseObj(firebase);
       }
    //    firebase.auth().onAuthStateChanged(function(user) {
    //     if (user) {
    //         console.log(user.toJSON())
    //    console.log(firebase.auth().currentUser)
    //     }
    //   });
   },[]);
   useEffect(()=>{
       if(firebaseStore){
        if(storage.isData){
            getData(storage.keyData);
          }
          else{
            dispatch({ type: reqTypes.SET_LOADER, payload: false });
          }
       }
   
   },[firebaseStore]);  
   const getData=async(id)=>{     
    if(firebaseStore){
            let data=await firebaseStore.collection('users').doc(id).get();
            if(data && data.exists){
                let source=data.data();
                //moment.utc(new Date(1595273775*1000)).local().format();
                dispatch({ type: reqTypes.SET_GEN_INFO, payload: {data:{...source,key:id},isLoading:false} });
            }
            else{
                dispatch({ type: reqTypes.SET_LOADER, payload: false });
            }
    }
    else{
        storage.isInit("");
        dispatch({ type: reqTypes.SET_LOADER, payload: false });
    }
   }
   const setFireData=async(data)=>{
      
        if(firebaseStore){
            let response=null;
            if(storage.isData){
                response=await firebaseStore.collection("users").doc(storage.keyData);
                let user=await response.get();
                if(!user.exists){
                    storage.isInit='';
                }
            }
            if(storage.isData){
                
                response=await firebaseStore.collection("users").doc(storage.keyData);
                let user=await response.get();
                let created=user.data().created;
                let updateInfo=await response.set({...data,modified: firebaseApp.firebase_.firestore.FieldValue.serverTimestamp(),created });
            }
            else
            { response=await firebaseStore.collection("users").add({...data,created: firebaseApp.firebase_.firestore.FieldValue.serverTimestamp() ,modified:firebaseApp.firebase_.firestore.FieldValue.serverTimestamp()});} 
            let newData=await firebaseStore.collection('users').doc(response.id).get();
            if(newData&& newData.exists){
                let source=newData.data();
                //moment.utc(new Date(1595273775*1000)).local().format();
                setLastInsertedItem({...source,key:response.id});
                dispatch({ type: reqTypes.SET_GEN_INFO, payload: {data:{...source,key:response.id},isLoading:false} });
            }
            else{
                dispatch({ type: reqTypes.SET_LOADER, payload: false });
            }
         
        }
        else{
            dispatch({ type: reqTypes.SET_LOADER, payload: false });
        }
   }
   return [firebaseStore,lastInsertedItem,setFireData,getData,firebaseObj];
}

export {useFirebase};