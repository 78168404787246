import React, { useState, useEffect } from 'react';
import * as reqTypes from './redux/actionType'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import Layout from './components/Layout'
import Home from './components/Home'
import Administration from './components/Administration'
import { useDispatch, useSelector } from "react-redux";
import Login from './components/Administration/Login';
import Thankyou from './components/Home/Thankyou';


const App =() => {
  const dispatch = useDispatch();
  const state = useSelector(state => state); 
  const history = useHistory();
  return <Layout>
            <Route exact path='/' component={Home} />  
          
            <Route exact path='/Administration' component={Administration} />  
         </Layout>
};
export default App;
