import React, { useEffect } from 'react'
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider,Button,Backdrop,FormControl,FormLabel,RadioGroup,FormControlLabel,Radio,Checkbox} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useFirebase} from '../Firebase'
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as reqTypes from '../../redux/actionType'
import storage from '../../storage';
import Thankyou from './Thankyou';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

  const useStyles = makeStyles((theme) => ({
    paperContainer:{
        position:'relative',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        paddingBottom:'100px'
    },   
    paper: {
       padding:"1rem",
       margin:'1rem',
       width:'100%'
    },
    input: {
        marginBottom:'0.75rem',
        '& .MuiInputLabel-formControl[data-shrink="false"]': {
            textOverflow: 'ellipsis !important',
            fontSize:'0.85rem'
          }
      },
      divider:{
          width:'100%',
          margin:'1rem'
      },
      button:{
          width:'100%',
          marginTop:'2rem',
          marginBottom:'2rem',
          padding:'1rem'
      },
      backdrop:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:1
      },
      confirmationBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        padding:'5rem'
      },
      profileDetails:{
        width:'100%'
      },
      customFormLabel:{
        alignItems:'flex-start',
        marginTop:'0.75rem',
        '& .MuiCheckbox-root':{
          paddingTop: '0.3rem'
        }
      }
}));

export default  (props)=>{
  const [formValues,setFormValues]=React.useState({name:'',phone:'',email:'',promotions:false});
  const [isDisabled,setIsDisabled]=React.useState(true);
  const [showDialog,setShowDialog]=React.useState(false);  
  const [showProgress,setShowProgress]=React.useState(false); 
  const history = useHistory();
  const dispatch=useDispatch();
  const state = useSelector(state => state); 
  const classes=useStyles();
  const [fireStore,lastInsertedItem,setFireData,getUserData]=useFirebase();
  useEffect(()=>{
    if(lastInsertedItem!=null)
    {
      setShowDialog(true);
     
       if(!storage.isData)
       storage.isInit=lastInsertedItem?lastInsertedItem.key:'';
      
    }
      
  },[lastInsertedItem]);
  
  const handleOnchange=(e)=>{
      let val=e.target.value;

      const field=e.target.name;
      if(field==="promotions")val=e.target.checked;
      formValues[field]=val;
      let disabled=checkDisabled(formValues);
      setIsDisabled(disabled);
      setFormValues({...formValues});
  }
  const checkDisabled=(fields)=>{    
      if(fields["name"]==null||fields["name"].trim().length<=0) return true;
      if((fields["phone"]==null||fields["phone"].trim().length<=0) &&(fields["email"]==null ||fields["email"].trim().length<=0) ) return true;
      if(fields["purpose"]==null||fields["purpose"].trim().length<=0) return true;
      return false;
  }
  const handleSubmit=async()=>{
    if(!checkDisabled(formValues)){
      setShowProgress(true);
      let reponse=await setFireData(formValues);
      setShowProgress(false);
     
    }
  }
 
  const hasUserData=state.general && state.general.userData;
  if(showDialog){
    return <Thankyou></Thankyou>;
  }

     return <React.Fragment>          
         {!hasUserData &&!state.general.isLoading && <div  className={classes.paperContainer}>
            <Paper elevation={3} className={classes.paper}>
               <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Typography variant="h4" component="h2">
                Visitor Registration
                </Typography>
                <Divider variant="middle" className={classes.divider} />
               </Box>
                <form className={classes.form} noValidate autoComplete="disabled">
                  <TextField onChange={handleOnchange} value={formValues.name} name="name" className={classes.input} autoComplete="disabled" fullWidth label="Full Name *" />
                  <TextField onChange={handleOnchange} value={formValues.phone} name="phone" className={classes.input} autoComplete="disabled" fullWidth label="Phone No *" />
                  <TextField  onChange={handleOnchange} value={formValues.email} name="email" className={classes.input} autoComplete="disabled" fullWidth label="Email Address *" />
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Purpose of visit</FormLabel>
                    <RadioGroup aria-label="pv" color="primary"  name="purpose" value={formValues.purpose} onChange={handleOnchange}>
                      <FormControlLabel value="Day guest"  control={<Radio  color="primary" />} label="Day guest" />
                      <FormControlLabel  value="Staying at hotel" control={<Radio  color="primary" />} label="Staying at hotel" />
                    </RadioGroup>
                  </FormControl>
                
                </form>
                <FormControl component="fieldset" style={{marginTop:'0.50rem'}}>
                    <FormLabel component="legend" >The Elvetham would like to send you details of any promotions and special offers for you to take advantage of.  Be assured we will never send your details on to third parties.</FormLabel>
                    <FormControlLabel
                    
                    className={classes.customFormLabel}
                    control={<Checkbox color="primary" checked={formValues.promotions} value={formValues.promotions} onChange={handleOnchange} name="promotions" />}
                    label="Yes please – I would like to know about any promotions or special offers."
                    />
                  </FormControl>
                <Button disabled={isDisabled||showProgress} onClick={handleSubmit} variant="contained" className={classes.button} color="primary">                
                {showProgress?<CircularProgress></CircularProgress>:<Typography>SUBMIT</Typography>}
                </Button>
                </Paper>
            </div>
          }
          {hasUserData &&!state.general.isLoading  &&<div className={classes.paperContainer}>
          <Paper elevation={3} className={classes.paper}>
               <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Typography variant="h4" component="h2">
                  Your Guest Profile
                </Typography>
                <Divider variant="middle" className={classes.divider} />
               </Box>
                    <Card className={classes.profileDetails}>
                      <CardActionArea>
                        
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {state.general.userData.name}
                    </Typography>
                      {state.general.userData.email && state.general.userData.email.trim().length>0 &&     <Typography variant="body2" color="textSecondary" component="p">
                          <AlternateEmailIcon fontSize="small" style={{fontSize:'0.75rem'}}></AlternateEmailIcon> {state.general.userData.email}
                    </Typography>}
                    {state.general.userData.phone && state.general.userData.phone.trim().length>0 &&<Typography variant="body2" color="textSecondary" component="p">
                         <PhoneIcon  fontSize="small" style={{fontSize:'0.75rem'}}></PhoneIcon> {state.general.userData.phone}
                    </Typography>}
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" onClick={e=>{
                          let {name,phone,email,purpose,promotions}=state.general.userData;
                          setFormValues({name,phone,email,purpose,promotions});
                          dispatch({ type: reqTypes.SET_GEN_INFO, payload: null });
                        }} color="primary">
                          Edit
                        </Button>
                      </CardActions>
                    </Card>
               </Paper>
          </div>

          }
            <Backdrop className={classes.backdrop} open={showDialog} onClick={e=>{setShowDialog(false)}}>
            <Paper className={classes.confirmationBox} onClick={e=>{setShowDialog(false)}}>
                    <CheckCircleIcon  color="primary"></CheckCircleIcon>
                    <Typography color="primary">Registration Completed!</Typography>
            </Paper>
            </Backdrop>
        </React.Fragment>

}