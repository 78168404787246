import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import MapIcon from '@material-ui/icons/Map';
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import {ArrowBack} from  '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {auth,firestore} from './Firebase'
import * as reqTypes from '../redux/actionType'
const CustomBottomNavigation = withStyles((theme) => ({
        root: {
                backgroundColor: theme.palette.secondary.main,
                zIndex: '999',
                color: theme.palette.text.primary,
                '& .Mui-selected': {
                        color: '#FFF',
                },
                '& .Mui-selected .MuiSvgIcon-root': {
                        color: '#FFF',
                },
                '& .MuiBottomNavigationAction-root': {
                        color: theme.palette.text.primary,
                }

        }
}))(BottomNavigation);
const useStyles = makeStyles((theme) => ({
        bodyContainer:{
            position:'relative',
            paddingTop:'64px',
            paddingBottom:'64px',
        },
        
    }));
    
const Layout=(props)=>{
       
        const dispatch = useDispatch();
        const state = useSelector(state => state.general);
        const history = useHistory();
        const classes = useStyles();
        const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
       
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
          debugger;
        auth.signOut()
        .then(function() {
             
                dispatch({ type: reqTypes.SET_AUTH, payload: {authUser:null,isSuccess:false,errorMessage:''} });
        })
        .catch(function(error) {
                debugger;
          // An error happened
        });
  };
        return <div style={{ width: '100%' }}> 
          <AppBar>
                <Toolbar>
                        <img src="/EVSMALL.png" height="50" style={{marginRight:'1rem'}}></img>
                        <Typography variant="h6" style={{flexGrow:1}}></Typography>
                        {state.authUser && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={e=>{setAnchorEl(null)}}
              >
                <MenuItem onClick={handleClose}>Logout</MenuItem>
                {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              </Menu>
            </div>
          )}
                </Toolbar>
            </AppBar>
         <div className={classes.bodyContainer}>
         {props.children}
         </div>
        </div>
}
export default Layout;