const  firebaseConfig = {
  apiKey: "AIzaSyB_rt-SE8M5gV0HuqgwYY_K7rTqz3pnw8I",
  authDomain: "elevithamhotel-vr.firebaseapp.com",
  databaseURL: "https://elevithamhotel-vr.firebaseio.com",
  projectId: "elevithamhotel-vr",
  storageBucket: "elevithamhotel-vr.appspot.com",
  messagingSenderId: "716756576410",
  appId: "1:716756576410:web:19a4670839099515c9d723"
  };

  export default firebaseConfig;