import React, { useEffect } from 'react'
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider,Button,Backdrop} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {auth,firestore} from '../Firebase'
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as reqTypes from '../../redux/actionType'
import storage from '../../storage';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    paperContainer:{
        position:'relative',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        paddingBottom:'100px'
    },   
    paper: {
       padding:"1rem",
       margin:'1rem',
       width:'100%'
    },
    input: {
        marginBottom:'0.75rem',
        '& .MuiInputLabel-formControl[data-shrink="false"]': {
            textOverflow: 'ellipsis !important',
            fontSize:'0.85rem'
          }
      },
      divider:{
          width:'100%',
          margin:'1rem'
      },
      button:{
          width:'100%',
          marginTop:'2rem',
          marginBottom:'2rem',
          padding:'1rem'
      },
      backdrop:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:1
      },
      confirmationBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        padding:'5rem'
      },
      profileDetails:{
        width:'100%'
      }
}));
export default  (props)=>{
    const [formValues,setFormValues]=React.useState({email:'',password:''});
    const classes=useStyles();
    const [isDisabled,setIsDisabled]=React.useState(true);
     const [showDialog,setShowDialog]=React.useState(false);  
     const [showProgress,setShowProgress]=React.useState(false); 
     const state = useSelector(state => state); 
     const history = useHistory();
     const dispatch=useDispatch();
    const handleOnchange=(e)=>{
        const val=e.target.value;
        const field=e.target.name;
        formValues[field]=val;
        let disabled=checkDisabled(formValues);
        setIsDisabled(disabled);
        setFormValues({...formValues});
    }
    const checkDisabled=(fields)=>{    
     
        if(fields["password"]==null||fields["password"].trim().length<=0) return true;
        if(fields["email"]==null||fields["email"].trim().length<=0) return true;
        return false;
    }
   
    const dosignIn=()=>{
        if(!checkDisabled(formValues)){
            setShowProgress(true);
            auth.signInWithEmailAndPassword(formValues.email,formValues.password).then(function(firebaseUser) {
                setShowProgress(false);
                if(firebaseUser){
                   let jsoData=firebaseUser.user.toJSON();
                   dispatch({ type: reqTypes.SET_AUTH, payload: {authUser:{uid:jsoData.uid,email:jsoData.email},isSuccess:true} });
                }
               })
               .catch(function(error) {
                 setShowProgress(false);
                   var errorCode = error.code;
                   var errorMessage = error.message;
                   dispatch({ type: reqTypes.SET_AUTH, payload: {authUser:null,isSuccess:false,errorMessage} });
               });;
          //   let reponse=await setFireData(formValues);
          
          }
    }
    const handleSubmit=()=>{
        dosignIn();
      }
  
    return <React.Fragment>          
    <div  className={classes.paperContainer}>
       <Paper elevation={3} className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
           <Typography variant="h4" component="h2">
            Login
           </Typography>
           <Divider variant="middle" className={classes.divider} />
          </Box>
           <form className={classes.form} noValidate autoComplete="disabled">             
             <TextField  onChange={handleOnchange} value={formValues.email} name="email" className={classes.input} autoComplete="disabled" fullWidth autoFocus label="Email Address *" />
             <TextField type="password"  onChange={handleOnchange} value={formValues.password} name="password" className={classes.input} autoComplete="disabled" fullWidth label="Password *" />
           </form>
            <Typography>{state.general.errorMessage}</Typography>
           <Button disabled={isDisabled||showProgress} onClick={handleSubmit} variant="contained" className={classes.button} color="primary">                
           {showProgress?<CircularProgress></CircularProgress>:<Typography>LOGIN</Typography>}
           </Button>
           </Paper>
       </div>
     </React.Fragment>
}