import React, { useEffect } from 'react'
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider,Button,Backdrop,FormControl,FormLabel,RadioGroup,FormControlLabel,Radio} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useFirebase} from '../Firebase'
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as reqTypes from '../../redux/actionType'
import {auth,firestore} from '../Firebase'
const useStyles = makeStyles((theme) => ({
    paperContainer:{
        position:'relative',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        paddingBottom:'100px'
    },   
    paper: {
       padding:"1rem",
       margin:'1rem'
    },
    input: {
        marginBottom:'0.75rem',
        '& .MuiInputLabel-formControl[data-shrink="false"]': {
            textOverflow: 'ellipsis !important',
            fontSize:'0.85rem'
          }
      },
      divider:{
          width:'100%',
          margin:'1rem'
      },
      button:{
          width:'100%',
          marginTop:'2rem',
          marginBottom:'2rem'

      },
      backdrop:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:1
      },
      confirmationBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        padding:'5rem'
      },
      profileDetails:{
        width:'100%'
      }
}));

export default  (props)=>{
    const classes=useStyles();
    const state = useSelector(state => state.general); 
    useEffect(()=>{
      window.setTimeout(()=>{
        window.location.replace('https://elvethamhotel.co.uk/');
        window.location.href="https://elvethamhotel.co.uk/";
      },5000);
    },[])
    const dispatch=useDispatch();
    // useEffect(()=>{
    //     async function fetchData() {
    //         if(!state.isLoading){
    //            await fetchInfo();
    //         }    
    //     }
    //     fetchData();
    // },[]);
    // const fetchInfo=async()=>{
    //     if(!state.isLoading){
    //        dispatch({ type: reqTypes.SET_LOADER, payload: true });
    //         const first =firestore.collection("configdata").limit(1);
    //         const snapshot = await first.get();
    //         let source=snapshot.docs.map(doc => doc.data());
    //         debugger;
    //         dispatch({ type: reqTypes.SET_CONFIG_DATA, payload: source[0] });
    //     }
    // }
    return   <Paper elevation={3} className={classes.paper}>
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
     <Typography variant="h6" component="h2">
     Thank you for submitting your information for track and trace purposes.
     </Typography>
     <Divider variant="middle" className={classes.divider} />
    </Box>
       {/* {state.configData?state.configData.message:''} */}
        <Typography>
            We are committed to you, our guest, with providing you with a safe and hygienic environment whilst receiving the best possible service and having the most enjoyable stay.
        </Typography>
        <Typography>
        From all our team members, welcome.
        </Typography>
     </Paper>
}